<template>
  <SubPageWrapper>
    <SpreadsheetUploadModal :id="modalsId.SPREADSHEET_UPLOAD" />

    <template #loader>
      <SlOverlay :show="isProcessing" />
    </template>

    <template #left-col>
      <SlTabList
        v-model="tabModel"
        :tabs="settingTabs"
      >
        <template #header>
          {{ $t('Web.DbImport.LabelSettings') }}
        </template>
      </SlTabList>
      <SlTabList
        v-model="tabModel"
        :tabs="importTabs"
        :disabled="tabsDisabled"
      >
        <template #header>
          {{ $t('Web.DbImport.LabelToImport') }}
          <SlInfoButton
            v-tooltip="getTooltip({
              content: $t('Web.DbImport.TooltipToImport')
            })"
          />
        </template>
      </SlTabList>
    </template>
    <ConnectorContentWrapper
      :title="title"
      :is-valid-title="isValidTitle"
    >
      <template #actions>
        <SlButton
          variant="secondary"
          color="grey"
          @click="goBack"
        >
          {{ $t('Common.Cancel') }}
        </SlButton>
        <SlButton
          v-if="$sl_isEditingRoute"
          variant="secondary"
          @click="handleSaveSettings"
        >
          {{ $t('Common.Save') }}
        </SlButton>
        <SlButton @click="handleImport">
          {{ importLabel }}
        </SlButton>
      </template>
      <template #alerts>
        <SlAlert
          v-if="warningText"
          type="warn"
          :accent="warningText"
        />
      </template>
      <template>
        <SlTabContent
          v-for="tab in allTabs"
          :key="tab.value + !!files.length"
          :value="tab.value"
          :tab-value="tabModel"
        >
          <ValidationObserver
            :ref="`${tab.value}-observer`"
            class="tab-observer"
          >
            <component
              :is="tab.component"
              :tab-key="tab.value"
              :tab-title="tab.label"
              :invalid-tab-value="invalidTabValue"
            />
          </ValidationObserver>
        </SlTabContent>
      </template>
      <template #main-footer>
        <ConnectorTabsNavigation
          :tabs="paginationTabs"
          :current-tab="tabModel"
          @tab-switch="handleTabSwitch"
        />
      </template>
    </ConnectorContentWrapper>
  </SubPageWrapper>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import ConnectorContentWrapper from '@/components/Connections/Common/ConnectorContentWrapper';
import ConnectorContentBlock from '@/components/Connections/Common/ConnectorContentBlock';
import General from '@/components/Connections/Spreadsheet/Tabs/General';
import MatchSheets from '@/components/Connections/Spreadsheet/Tabs/MatchSheets';
import Import from '@/components/Connections/Spreadsheet/Tabs/Import';
import ConnectorTabsNavigation from '@/components/Connections/Common/ConnectorTabsNavigation';
import SpreadsheetUploadModal from '@/components/Modals/Connections/Connectors/Spreadsheet/SpreadsheetUploadModal.vue';
import { modal } from '@/mixins/modal';
import { metaInfo } from '@/mixins/metaInfo';
import modalsId from '@/config/shared/modalsId.config';
import { settingTabs, importTabs, tabKeys, matchKeys } from '@/config/connection/spreadsheet.config';
import { routeNames } from '@/config/router/router.config';
import { connectionTypes } from '@/config/connection';
import { updateFgsFlags } from '@/config/api/operations.config';
import { toArray } from '@/helpers/utils/toArray';
import { preventTabClose } from '@/helpers/shared/webAPI';
import { getTooltip } from '@/helpers/shared/tooltip';
import { dealWith } from '@/api/helpers/errorRegistry';
import { ampli } from '@/plugins/product/ampli';

export default {
  name: 'Spreadsheet',
  components: {
    SpreadsheetUploadModal,
    ConnectorTabsNavigation,
    ConnectorContentBlock,
    ConnectorContentWrapper,
    General,
    MatchSheets,
    Import
  },
  mixins: [modal, metaInfo],
  data() {
    return {
      modalsId,
      tabModel: tabKeys.GENERAL,
      isValidTitle: true,
      isProcessing: false,
      isEdited: false,
      isRedirect: false,
      tabsDisabled: false,
      warningSensitiveKeys: [
        'csvDelimiter',
        'composeDate',
        'headerRowsCount'
      ],
      invalidTabValue: null,
      getTooltip
    };
  },
  provide() {
    return {
      getTooltip: this.getTooltip,
      setSettings: this.updateSettingsCallback,
      setTab: this.setTab,
      setIsEdited: this.setIsEdited,
      resetTabValidation: this.resetTabValidation
    };
  },
  inject: ['handleReImport'],
  computed: {
    ...mapState({
      projectNameGsl: (state) => state.project.project?._name || this.$t('Web.Spreadsheet.TitleEdit'),
      connectorState: (state) => state.spreadsheet,
      warningText: (state) => state.spreadsheet.warning,
      dataType: (state) => state.spreadsheet.commonData.type,
      files: (state) => state.spreadsheet.files,
      connectionDataByType: (state) => state.connection.connectionData,
      selectedConnector: (state) => state.connection.selectedConnector
    }),
    ...mapGetters({
      projectLabel: 'project/projectLabel',
      dataByTab: 'spreadsheet/dataByTab',
      isCsv: 'spreadsheet/isCsv',
      isMultipleFiles: 'spreadsheet/isMultipleFiles'
    }),
    connectionData() {
      return this.connectionDataByType[this.selectedConnector];
    },
    tabData() {
      return this.dataByTab(this.tabModel);
    },
    matchedSheets() {
      return this.dataByTab(tabKeys.MATCH_SHEETS);
    },
    settingTabs() {
      return this.disableTabs(this.filterTabs(settingTabs(this)), (tab) => {
        return tab.value !== tabKeys.GENERAL && this.tabsDisabled;
      });
    },
    importTabs() {
      return this.disableTabs(this.filterTabs(importTabs(this, this.dataType)), (tab) => {
        if (!this.isMultipleFiles && this.isCsv) {
          return false;
        }

        return !this.matchedSheets[tab.value][matchKeys.SHEET];
      });
    },
    allTabs() {
      return [
        ...this.settingTabs,
        ...this.importTabs
      ];
    },
    requiredTabs() {
      return this.importTabs.filter(tab => tab.required);
    },
    matchedTabs() {
      return this.importTabs.filter(tab => !tab.disabled);
    },
    paginationTabs() {
      return [
        ...this.settingTabs,
        ...this.matchedTabs
      ];
    },
    title() {
      if (this.$sl_isEditingRoute) {
        return this.$t('Web.BaseConnectors.TitleEdit', {
          1: this.projectLabel
        });
      }

      return this.connectionData?.commonData?.slProjectName || '';
    },
    errorNotifyTitle() {
      return this.$sl_isEditingRoute
        ? this.$t('Web.DbImport.Errors.NotifyTitleReimport')
        : this.$t('Web.DbImport.Errors.NotifyTitleImport');
    },
    importLabel() {
      return this.$sl_isEditingRoute
        ? this.$t('Import.Dialog.Reimport')
        : this.$t('Import.Dialog.Import');
    }
  },
  async beforeMount() {
    this.selectConnector(this.connectorState.commonData.type || connectionTypes.SPREADSHEET_TRANSACTIONAL);

    try {
      this.isProcessing = true;

      if (!this.$sl_isEditingRoute) {
        await this.fetchBasicSettings();
        await this.matchDefaultSheet();

        await Promise.allSettled([
          this.fetchWarnings(),
          ...this.matchedTabsSlotsRequests()
        ]);

        return;
      }

      await this.getConnectionInfo();

      await Promise.allSettled([
        this.fetchSpreadsheetInfo(),
        this.fetchWarnings(),
        ...this.matchedTabsSlotsRequests(),
        ...this.matchedTabsSheetListRequests()
      ]);

      if (!this.files?.length) {
        this.handleReImport();
      }
    } finally {
      this.isProcessing = false;
    }
  },
  beforeDestroy() {
    preventTabClose();
  },
  destroyed() {
    this.resetState();
  },
  methods: {
    ...mapActions({
      selectConnector: 'connection/selectConnector',
      getConnectionInfo: 'connection/getConnectionInfo',
      importConnectionInfo: 'connection/importConnectionInfo',
      reimportConnectionInfo: 'connection/reimportConnectionInfo',
      saveConnectionInfo: 'connection/saveConnectionInfo',
      updateTabSettings: 'spreadsheet/updateTabSettings',
      fetchBasicSettings: 'spreadsheet/fetchBasicSettings',
      fetchTablePreview: 'spreadsheet/fetchTablePreview',
      setTablePreviewResult: 'spreadsheet/setTablePreviewResult',
      fetchSlots: 'spreadsheet/fetchSlots',
      fetchSheetList: 'spreadsheet/fetchSheetList',
      fetchWarnings: 'spreadsheet/fetchWarnings',
      resetIsTabsLoaded: 'spreadsheet/resetIsTabsLoaded',
      matchDefaultSheet: 'spreadsheet/matchDefaultSheet',
      fetchSpreadsheetInfo: 'spreadsheet/fetchSpreadsheetInfo',
      resetState: 'spreadsheet/resetState',
      subscribe: 'operations/subscribe',
      logout: 'user/logout'
    }),
    // calls from wrapper
    beforeRouteLeaveGuard(to, from, next) {
      if (this.isRedirect) {
        return next();
      }

      if (this.isEdited) {
        return this.showExitConfirm(next);
      }

      next();
    },
    filterTabs(tabs) {
      if (!this.isMultipleFiles && this.isCsv) {
        return toArray(tabs[0]);
      }

      return tabs;
    },
    disableTabs(tabs, isDisabled) {
      return tabs.map(tab => {
        if (isDisabled(tab)) {
          return {
            ...tab,
            disabled: true
          };
        }

        return tab;
      });
    },
    matchedTabsSlotsRequests() {
      return this.matchedTabs.map(tab => this.fetchSlots(tab.value));
    },
    matchedTabsSheetListRequests() {
      return this.matchedTabs.map(tab => {
        const fileId = this.connectorState[tabKeys.MATCH_SHEETS][tab.value][matchKeys.FILE];

        return this.fetchSheetList(fileId);
      });
    },
    setIsEdited() {
      if (this.isEdited) {
        return;
      }

      this.isEdited = true;
      preventTabClose(true);
    },
    resetTabValidation() {
      this.invalidTabValue = null;
    },
    shouldCheckWarnings(key) {
      return this.warningSensitiveKeys.includes(key);
    },
    async updateSettingsCallback(payload) {
      this.setIsEdited();

      await this.updateTabSettings({
        tab: this.tabModel,
        ...payload
      });

      const isValid = await this.validateGeneralTab();

      if (isValid && this.shouldCheckWarnings(payload.key)) {
        this.fetchWarnings();
      }
    },
    setTab(tab) {
      this.tabModel = tab;
    },
    showExitConfirm(next) {
      this.showModal(modalsId.SL_CONFIRM_MODAL, {
        icon: 'style_save_double',
        title: this.$t('Web.Modals.UnsavedConfirm.TitleLeaveProject'),
        text: this.$t('Web.Modals.UnsavedConfirm.TextLeaveProject'),
        confirmText: this.$t('Web.Modals.UnsavedConfirm.ButtonLeave'),
        confirmCallback: () => {
          this.isRedirect = true;

          next();
        }
      });
    },
    async fetchPreview(tab) {
      try {
        this.updateTabSettings({
          tab,
          key: 'isLoading',
          value: true
        });

        const { operationData } = await this.subscribe({
          subscriber: () => this.fetchTablePreview(tab)
        });

        this.setTablePreviewResult({
          data: operationData,
          tab
        });
      } catch (e) {
        const errorMessage = e?.message?.error || e?.message;

        if (errorMessage) {
          this.$notify({
            type: 'error',
            text: errorMessage,
            duration: 15000
          });
        }
      } finally {
        this.updateTabSettings({
          tab,
          key: 'isLoaded',
          value: true
        });

        this.updateTabSettings({
          tab,
          key: 'isLoading',
          value: false
        });
      }
    },
    validateTab() {
      const observer = this.$refs[`${this.tabModel}-observer`][0];

      if (!observer) {
        return true;
      }

      return observer.validate();
    },
    async validateGeneralTab() {
      if (this.tabModel !== tabKeys.GENERAL) {
        return;
      }

      const isValid = await this.validateTab();

      this.tabsDisabled = !isValid;
      this.resetIsTabsLoaded();

      return isValid;
    },
    checkUnmatchedTab(tabs) {
      if (this.tabsDisabled) {
        return this.settingTabs[0];
      }

      return tabs.find(tab => !(this.dataByTab(tabKeys.MATCH_SHEETS)[tab.value][matchKeys.SHEET]));
    },
    checkUnmatchedSlots(tabs) {
      let result = null;

      tabs.find(({ value: tabKey }) => {
        const tabData = this.dataByTab(tabKey);
        const matchedSlots = Object.values(tabData.matchedSlots);
        const unmatchedSlots = tabData.availableSlots.reduce((acc, slot) => {
          if (slot._isRequired !== 'true') {
            return acc;
          }

          if (!matchedSlots.includes(slot.colMeaning)) {
            acc.push(slot.name);
          }

          return acc;
        }, []);

        if (unmatchedSlots.length) {
          result = {
            tab: tabKey,
            slots: unmatchedSlots
          };

          return true;
        }

        return false;
      });

      return result;
    },
    async validateMatchedTabs() {
      const unmatchedTab = this.checkUnmatchedTab(this.requiredTabs);
      const unmatchedSlots = this.checkUnmatchedSlots(this.matchedTabs);

      if (!unmatchedTab && !unmatchedSlots) {
        return true;
      }

      if (unmatchedTab) {
        if (this.tabsDisabled) {
          return;
        }

        this.invalidTabValue = unmatchedTab.value;
        this.tabModel = tabKeys.MATCH_SHEETS;

        this.$notify({
          type: 'error',
          title: this.errorNotifyTitle,
          text: this.$t('Web.Spreadsheet.NotifyMatchSheetWarningText', { 1: unmatchedTab.label }),
          duration: 15000
        });

        return false;
      }

      if (unmatchedSlots) {
        const tabData = this.dataByTab(unmatchedSlots.tab);

        if (tabData.isLoaded) {
          this.tabModel = unmatchedSlots.tab;
        } else {
          try {
            await Promise.allSettled([
              this.fetchPreview(unmatchedSlots.tab),
              this.fetchSlots(unmatchedSlots.tab)
            ]);

            return this.validateMatchedTabs();
          } finally {
            this.updateTabSettings({
              tab: unmatchedSlots.tab,
              key: 'isLoaded',
              value: true
            });
          }
        }

        this.$notify({
          type: 'error',
          title: this.errorNotifyTitle,
          text: this.$t('Web.Spreadsheet.NotifyMatchColsWarningText', { 1: unmatchedSlots.slots.join(', ') }),
          duration: 15000
        });

        return false;
      }
    },
    validate() {
      if (!this.files.length) {
        this.$notify({
          type: 'error',
          title: this.$t('Web.Spreadsheet.ErrorTitleNoFile'),
          text: this.$t('Web.Spreadsheet.ErrorTextNoFile'),
          duration: 15000
        });

        return false;
      }

      return this.validateMatchedTabs();
    },
    async handleImport() {
      const isValid = await this.validate();

      this.isValidTitle = !!this.title;

      if (!isValid || !this.isValidTitle) {
        return;
      }

      try {
        this.isProcessing = true;

        const action = this.$sl_isEditingRoute ? this.reimportConnectionInfo : this.importConnectionInfo;

        const { subscriberData } = await this.subscribe({
          subscriber: () => action({ data: this.connectorState }),
          ignoreLastChanges: !this.$sl_isEditingRoute,
          ui_flags: async({ operationId, flags }) => {
            if (flags.includes(updateFgsFlags.IMPORT_META_INFO)) {
              const metaInfo = await this.fetchMetaInfo(operationId);

              this.handleImportMetaInfo(metaInfo, operationId);
            }
          }
        });

        this.isRedirect = true;

        if (!this.$sl_isEditingRoute) {
          ampli.projectCreated();

          return this.$store.dispatch('manageProjects/openProject', {
            ...subscriberData
          });
        } else {
          this.$notify({
            type: 'success',
            title: this.$t('Web.UpdateData.Success')
          });
        }

        this.$router.push({
          name: routeNames.DEMAND
        });
      } catch (e) {
        dealWith({
          '*': {
            title: this.$t('Web.DbImport.Errors.NotifyTitleImport'),
            text: (e) => e.message
          }
        })(e);

        this.logout({
          e,
          from: 'spreadsheet/handleImport'
        });
      } finally {
        this.isProcessing = false;
      }
    },
    async handleSaveSettings() {
      const isValid = await this.validate();

      if (!isValid) {
        return;
      }

      try {
        const response = await this.saveConnectionInfo({ data: this.connectorState });

        if (response?.data?.successful) {
          this.isEdited = false;

          this.$notify({
            type: 'success',
            title: this.$t('Web.Notification.TitleSavedSuccess')
          });
        }
      } catch (e) {
        const errorMessage = e?.message;

        if (errorMessage) {
          this.$notify({
            type: 'error',
            title: this.errorNotifyTitle,
            text: errorMessage,
            duration: -1
          });
        }
      } finally {
        this.isRedirect = false;

        this.$router.push({
          name: routeNames.DEMAND
        });
      }
    },
    goBack() {
      this.$router.back();
    },
    handleTabSwitch(tab) {
      this.tabModel = tab;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .connector-content__main {
    row-gap: 0;

    .sl-tab-content,
    .tab-observer {
      height: 100%;

      .connector-content-block {
        height: 100%;
      }

      .connector-content-block__main {
        height: 100%;
        max-height: calc(100% - 68px);
      }

      .sl-table-wrapper--before-outer {
        height: calc(100% - 24px) !important;
      }
    }
  }
}
</style>
